/**
 * Search Form, input field, clear button, and search button
 *
 * Separation of concerns is very important here, as the JS and templates
 * here have to work alongside external components (namely, autosuggest,
 * and eventually search history).
 *
 * Code here should only apply to the elements above(e.g. the basic search
 * form). Nothing that applies to a supplemental search form package should
 * be included here (for example: adjusting Search History state)
 *
 * This file is also the sole source of handling analytics events on
 * form submission. Outside components should send a 'termSelected' custom
 * event rather than calling submit() directly on the search form.
 */
import { getDomElems } from '../../../../_shared-components/getDomElems';
import { handleSearchFormSubmissionAnalytics } from './searchMetrics';

/**
 * Retrieves a search query parameter from the current URL.
 *
 * @param {string} param - The name of the query parameter to retrieve.
 * @returns {string|null} The value of the query parameter, or null if not found.
 */
const getSearchParam = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

/**
 * Displays the clear button and adjusts the search field's margin.
 *
 * @param {HTMLInputElement} searchField - The search input field element.
 * @param {HTMLElement} clearButton - The clear button element.
 */
const showClearButton = (searchField, clearButton) => {
  clearButton.removeAttribute('aria-hidden');
  clearButton.setAttribute('data-visible', 'visible');
  searchField.setAttribute('style', 'margin-right: -44px;');
};

/**
 * Hides the clear button and resets the search field's margin.
 *
 * @param {HTMLInputElement} searchField - The search input field element.
 * @param {HTMLElement} clearButton - The clear button element.
 */
const hideClearButton = (searchField, clearButton) => {
  clearButton.setAttribute('aria-hidden', 'true');
  clearButton.setAttribute('data-visible', 'hidden');
  searchField.setAttribute('style', 'margin-right: 0;');
};

/**
 * Toggles the visibility of the clear button based on the search field's value.
 *
 * @param {HTMLInputElement} searchField - The search input field element.
 * @param {HTMLElement} clearButton - The clear button element.
 */
const setClearButtonVisibility = (searchField, clearButton) => {
  if (!searchField.value) {
    hideClearButton(searchField, clearButton);
  } else {
    showClearButton(searchField, clearButton);
  }
};

/**
 * Enables or disables the search button based on the search field's value.
 *
 * @param {HTMLInputElement} searchField - The search input field element.
 * @param {HTMLButtonElement} searchButton - The search button element.
 */
const enableSearchButton = (searchField, searchButton) => {
  if (searchField.value.trim().length === 0) {
    searchButton.classList.add('search__search-button--disabled');
    searchButton.setAttribute('aria-disabled', 'true');
    searchButton.setAttribute('disabled', 'disabled');
  } else if (searchField.value.trim().length > 0) {
    searchButton.classList.remove('search__search-button--disabled');
    searchButton.setAttribute('aria-disabled', 'false');
    searchButton.removeAttribute('disabled');
  }
};

/**
 * Clears the search field's value and refocuses it.
 *
 * @param {HTMLInputElement} searchField - The search input field element.
 */
const clearSearchField = (searchField) => {
  const inputElem = searchField;
  inputElem.value = '';
  searchField.focus();
};

// used to ensure analytics don't send twice
let analyticsLogged = false;

/**
 * handle sending analytics
 * triggered on either natural search (term in box, enter/searchbutton)
 * Or when "termSelected" event is dispatched by autosuggest or recents
 *
 * @param {HTMLInputElement} searchField - The search input field element.
 */
const handleAnalytics = (searchField) => {
  if (analyticsLogged) return;
  const searchTerm = searchField.value;
  const ariaActivedescendant = searchField.getAttribute('aria-activedescendant');
  handleSearchFormSubmissionAnalytics(ariaActivedescendant, searchTerm);
  analyticsLogged = true;
};

/**
 * Handles the "termSelected" event, triggering analytics tracking,
 * and submitting the search form.
 *
 * @param {HTMLInputElement} searchField - The search input field element.
 * @param {HTMLFormElement} searchForm - The search form element.
 * @param {Event} e
 */
const handleTermSelected = (searchField, searchForm, e) => {
  e.preventDefault();
  handleAnalytics(searchField);
  searchForm.submit();
};

/**
 * Initializes the search form event listeners and sets up analytics tracking.
 *
 * @param {Object} selectors - The object containing CSS selectors for various form elements.
 * @param {string} selectors.searchForm - Selector for the search form element.
 * @param {string} selectors.searchField - Selector for the search input field.
 * @param {string} selectors.clearButton - Selector for the search clear button.
 * @param {string} selectors.searchButton - Selector for the search submit button.
 */
const init = (selectors) => {
  // check that all needed elements are available
  const searchElems = getDomElems(selectors);
  if (searchElems === null) return;

  analyticsLogged = false;

  const {
    searchForm,
    searchField,
    clearButton,
    searchButton,
  } = searchElems;

  // if we're on a search page with a query, populate the search field with it
  searchField.value = getSearchParam('q');
  // set initial clear button visibility based on above
  setClearButtonVisibility(searchField, clearButton);
  // enable or disable search button based on above
  enableSearchButton(searchField, searchButton);

  // Clear button event listeners
  clearButton.addEventListener('click', () => {
    clearSearchField(searchField);
    setClearButtonVisibility(searchField, clearButton);
    enableSearchButton(searchField, searchButton);
  });
  searchField.addEventListener('keyup', () => {
    setClearButtonVisibility(searchField, clearButton);
  });

  // search button event listener
  searchField.addEventListener('input', () => {
    enableSearchButton(searchField, searchButton);
  });

  // term submission/analytics event listeners
  document.addEventListener('termSelected', (e) => {
    handleTermSelected(searchField, searchForm, e);
  });
  searchForm.addEventListener('submit', (e) => {
    handleAnalytics(searchField, searchForm, e);
  });
};

export default init;
