import { analyticsCustomClickHandler } from '../../../../_shared-components/navigationMetrics';

const conditionalAnalytics = {
  searchHistory: {
    searchTermType: 'autosuggest-recentquery',
    linkName: 'searchbox-_-text-_-autosuggest',
    searchRecentQuerySource: 'product',
  },
  autosuggest: {
    searchTermType: 'autosuggest',
    linkName: 'searchbox-_-text-_-autosuggest',
  },
  natural: {
    searchTermType: 'natural',
    linkName: 'searchbox-_-text-_-searchbutton',
  },
};

/**
 * Determines the search type based on the aria-activedescendant value.
 *
 * @param {string} [ariaAD] - The aria-activedescendant value
 *   from the search field.
 * @returns {'searchHistory' | 'autosuggest' | 'natural'} - the type of
 *   search sumbission, listbox selection or text entry (natural)
 */
const getSearchType = (ariaAD) => {
  if (ariaAD) {
    if (ariaAD.startsWith('search-history')) return 'searchHistory';
    if (ariaAD.startsWith('autosuggest')) return 'autosuggest';
  }
  return 'natural';
};

/**
 * Extracts the index number from an aria-activedescendant value.
 *
 * @param {string} [ariaAD=''] - The aria-activedescendant attribute value.
 * @returns {string | null} - The extracted index if found, otherwise null.
 */
const getTermIndex = (ariaAD = '') => {
  const match = ariaAD.match(/-([^-]*)$/);
  const lastString = match?.[1];
  const isNumber = lastString && !Number.isNaN(Number(lastString));
  return isNumber ? lastString : null;
};

/**
 * Retrieves the appropriate analytics object for a given search type.
 *
 * @param {string} ariaAD - The aria-activedescendant value.
 * @param {'searchHistory' | 'autosuggest' | 'natural'} searchType
 *   The detected search type (see getSearchType)
 * @param {object} [condAnalytics=conditionalAnalytics] - additional analytics
 *   object, specific to the search type (see conditionalAnalytics above)
 * @returns {object} - The resolved analytics object, with position added.
 */
const getConditionalAnalytics = (
  ariaAD,
  searchType,
  condAnalytics = conditionalAnalytics
) => {
  const analytics = condAnalytics[searchType];
  if (searchType !== 'natural' && analytics) {
    // this is the position indicator regardless of which listbox
    analytics.autoSuggestPosition = getTermIndex(ariaAD);
  }
  return analytics || {};
};

/**
 * The finalized analtyics object
 *
 * @param {string} ariaAD - The aria-activedescendant value.
 * @param {string} searchTerm - The submitted search term.
 * @returns {object} - The complete analytics data for submission.
 */
const getSumbitAnalytics = (ariaAD, searchTerm) => {
  if (!searchTerm) return null;
  const searchType = getSearchType(ariaAD);

  return {
    events: 'event1',
    searchTermClicked: searchTerm,
    ...getConditionalAnalytics(ariaAD, searchType),
  };
};

/**
 * gets finalized analytics and submits them
 * also includes convenience logging for env without metrics available
 *
 * @param {string} ariaAD - The aria-activedescendant value.
 * @param {string} searchTerm - The submitted search term.
 */
const handleSearchFormSubmissionAnalytics = (ariaAD, searchTerm) => {
  const config = getSumbitAnalytics(ariaAD, searchTerm);
  if (!config) return;

  // help with reviewing analytics locally or on try server
  if (document?.location?.hostname !== 'www.rei.com') {
    /* eslint-disable no-console */
    console.group('Metrics link config - searchMetrics');
    Object.entries(config).forEach(([key, value]) => {
      console.log(`${key}: ${value}`);
    });
    console.groupEnd();
    /* eslint-enable no-console */
  }

  analyticsCustomClickHandler(config);
};

export {
  getSearchType,
  getTermIndex,
  getConditionalAnalytics,
  getSumbitAnalytics,
  handleSearchFormSubmissionAnalytics,
};
