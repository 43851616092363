/**
 * Autosuggest
 * Contains main functionality for the search autosuggest.
 */
import { getDomElems } from '../../../../_shared-components/getDomElems';
import filterResults from './filterResults';
import getScriptData from '../../util/getScriptData';
import { getKeyCode, code } from '../../../../_shared-components/keyCode';
import LISTBOX, { AUTOSUGGEST } from './constants';
import * as listbox from './listboxUtil';

/**
 * State holds the values for autosuggest and includes
 * @param {array} fetchedItems which is the result of the fetch call,
 * @param {array} filteredResults is an array of ten results,
*/
const state = {
  fetchedItems: [],
  filteredResults: [],
};

/**
 * Determines whether the autosuggest listbox is open.
 *
 * - Checks if the `suggestionBox` element exists in the state.
 * - Retrieves its `data-visible` attribute.
 * - Returns true if the value is 'visible', otherwise false.
 *
 * @returns {boolean} - true if the autosuggest listbox is open.
 */
const autosuggestListboxIsOpen = () => {
  const { suggestionBox } = state;
  const { dataset: { visible } } = suggestionBox;
  return visible === 'visible';
};

/**
 * Grabs Autosuggest data JSON from #gnav-data blob. If not found uses absolute url fallback value
 * @returns {string} url
 */
const getAutosuggestTarget = () => {
  const {
    autosuggestTarget: targetUrl = null,
  } = getScriptData('gnav-data') || {};
  return targetUrl === null ? AUTOSUGGEST.URL.FALLBACK : targetUrl;
};

/**
 * Pings an endpoint and assigns the results to state's fetchedItems property. If an error occurs,
 * it is logged to the dev console and fetchedItems is set to an empty array.
 * @param {string} fetchPoint: url to ping
 */
export const fetchData = (fetchPoint) => {
  fetch(fetchPoint)
    .then((response) => {
      const { ok, status, url } = response || {};
      if (!ok) {
        // eslint-disable-next-line no-console
        console.error(`url: ${url} is responding with status code: ${status}`);
      }
      return ok ? response.json() : [];
    })
    .then((response) => {
      state.fetchedItems = response;
    })
    // eslint-disable-next-line no-console
    .catch((e) => console.error(e));
};

/**
 * If resultTitle exists then returns string containing bolded substring via markup.
 * Otherwise returns unchanged searchTerm string.
 * @param {string} searchTerm
 * @param {string} resultTitle
 * @returns {*}
 */
const getInnerHtml = (searchTerm, resultTitle) => (
  resultTitle.replace(searchTerm, `<span style='font-weight:bolder;'>${searchTerm}</span>`)
);

/**
 * @param {number} resultCount - current number of available autosuggest options
 * @param {string} searchTerm - the characters contained within the search input
 * @returns {string} - description of available autosuggest options for a searchTerm,
 * including directions on how to navigation the open listbox.
 */
const getAriaLiveTextForResults = (resultCount, searchTerm) => (
  (resultCount === 1) ? `There is 1 result available that contains "${searchTerm}". ${LISTBOX.ARIA_LIVE.DIRECTIONS}`
    : `There are ${resultCount} results available that contain "${searchTerm}".${resultCount > 0 ? ` ${LISTBOX.ARIA_LIVE.DIRECTIONS}` : ''}`
);

/**
 * Will remove visual focus for autosuggest list item when mouse leaves element.
 * Updates aria-live text to total result count
 * @param e
 */
const onMouseLeave = (e) => {
  const { target } = e;
  const { suggestionBox, ariaLiveRegion, searchField } = state;
  const listEntries = suggestionBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR);
  /* only remove visual focus if target has it, otherwise do nothing */
  if (target.classList.contains(LISTBOX.CSS.VISUAL_FOCUS)) {
    listbox.resetListbox(searchField, listEntries);
    ariaLiveRegion.textContent = getAriaLiveTextForResults(
      listEntries.length,
      searchField.value.trim()
    );
  }
};

/**
 * Add visual focus to autosuggest list item.
 * @param e
 */
const onMouseEnter = (e) => {
  const { target } = e;
  const { suggestionBox, ariaLiveRegion, searchField } = state;
  const listEntries = suggestionBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR);
  listbox.setVisualFocus(searchField, target, listEntries);
  ariaLiveRegion.textContent = '';
};

/**
 * Creates and appends markup to the DOM containing the list of autosuggest options with the
 * relevant characters bolded.
 * @param {object[]} results Array of (at most 10) options
 *
 * About data-cnstrc-item-section and data-cnstrc-item-name attributes:
 * https://docs.constructor.com/docs/integrating-with-constructor-behavioral-tracking-data-driven-event-tracking#result-item
 * https://docs.constructor.com/docs/faq-api-what-is-a-section-as-referenced-in-the-api
 */
export const appendResults = (results) => {
  const { suggestionList } = state;
  /* remove contents from ul element */
  const ul = suggestionList;
  ul.innerHTML = '';
  /* add list items to ul */
  [...results].forEach(({ searchTerm, resultTitle }, index) => {
    const li = document.createElement('li');
    li.className = LISTBOX.CSS.LIST_ITEM;
    /* id with index used for tracking focus & building metrics */
    li.id = AUTOSUGGEST.LI_ID(index);
    li.innerHTML = getInnerHtml(searchTerm, resultTitle);
    li.setAttribute('role', 'option');
    li.setAttribute('aria-label', resultTitle);
    /* Add Constructor search beacon tracking attributes */
    li.setAttribute('data-cnstrc-item-section', 'Search Suggestions');
    li.setAttribute('data-cnstrc-item-name', resultTitle);
    listbox.addMouseMoveEventListeners(li, onMouseEnter, onMouseLeave);
    ul.appendChild(li);
  });
};

/**
 * Controls visibility / expand-collapse of autosuggestion listbox
 * @param {HTMLDivElement} suggestionBox - element that contains the list entries of recent
 * searches or filtered results
 * @param {HTMLInputElement} searchField - input element of search field
 * @param {boolean} isVisible - boolean used to determine state of visibility / expansion
 */
export const setSuggestionBoxVisibility = (suggestionBox, searchField, isVisible = false) => {
  if (!isVisible) {
    listbox.resetListbox(searchField, [...suggestionBox.querySelectorAll(LISTBOX.ALL_LI.SELECTOR)]);
  }
  searchField.parentElement.setAttribute('aria-expanded', `${isVisible}`);
  suggestionBox.setAttribute('data-visible', isVisible ? 'visible' : 'hidden');
};

const onFocus = (suggestionBox, e) => {
  const { target: searchField } = e;
  const searchTerm = searchField.value.trim();
  if (searchTerm.length > 0) {
    /* update state */
    state.filteredResults = filterResults(searchTerm, state);
    const { ariaLiveRegion, filteredResults } = state;
    appendResults(filteredResults);
    const isVisible = (filteredResults && filteredResults.length > 0);
    if (listbox.shouldUpdateVisibility(suggestionBox, isVisible)) {
      setSuggestionBoxVisibility(suggestionBox, searchField, isVisible);
    }
    ariaLiveRegion.textContent = getAriaLiveTextForResults(filteredResults.length, searchTerm);
  }
};

const onBlur = (suggestionBox, e) => {
  const { ariaLiveRegion } = state;
  const { target: searchField } = e;
  setSuggestionBoxVisibility(suggestionBox, searchField);
  ariaLiveRegion.textContent = '';
};

/**
 * Handles mouse down events on the autosuggest listbox.
 *
 * - Retrieves the currently selected <li> element.
 * - If no item is selected, the function exits.
 * - If the selected item has valid text, it dispatches a termSelected event.
 *
 * @param {HTMLInputElement} searchField - The search input field.
 * @param {HTMLElement} suggestionList - The autosuggest listbox.
 */
const onMouseDown = (searchField, suggestionList) => {
  const li = listbox.getSelectedElementByAria(searchField, suggestionList);
  if (!li) return;
  const { id, selectedText } = listbox.getTextAndIdFromListEl(li);
  if (!selectedText) return;
  listbox.dispatchTermSelectedEvent(searchField, selectedText, id);
};

/**
 * Handles the keyboard logic for list entries in SuggestionBox
 * @param e
 */
export const onKeyUp = (e) => {
  const {
    ariaLiveRegion,
    suggestionBox,
    searchField,
  } = state;
  // const { dataset: { visible } } = suggestionBox;
  if (!autosuggestListboxIsOpen()) return;
  const listEntries = suggestionBox.querySelectorAll(`.${LISTBOX.CSS.LIST_ITEM}`);
  const currentIndex = listbox.getIndexFromElement(suggestionBox.querySelector(`.${LISTBOX.CSS.VISUAL_FOCUS}`));
  const keyCodeVal = getKeyCode(e);
  const searchTerm = searchField.value.trim();
  switch (keyCodeVal) {
    case code.ARROW_DOWN: {
      const updatedIndex = listbox.getUpdatedIndexOnArrowDown(currentIndex, listEntries.length);
      listbox.setVisualFocus(searchField, listEntries[updatedIndex], listEntries);
      ariaLiveRegion.textContent = '';
    }
      break;
    case code.ARROW_UP: {
      const updatedIndex = listbox.getUpdatedIndexOnArrowUp(currentIndex, listEntries.length);
      listbox.setVisualFocus(searchField, listEntries[updatedIndex], listEntries);
      ariaLiveRegion.textContent = '';
    }
      break;
    case code.ESCAPE:
      listbox.setSearchField(searchField);
      searchField.focus();
      setSuggestionBoxVisibility(suggestionBox, searchField);
      ariaLiveRegion.textContent = '';

      break;
    case code.END:
      searchField.focus();
      /* places cursor at the end text in field (if any) */
      searchField.setSelectionRange(searchField.value.length, searchField.value.length);
      listbox.resetListbox(searchField, listEntries);
      ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);

      break;
    case code.HOME:
      searchField.focus();
      listbox.resetListbox(searchField, listEntries);
      ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);

      break;
    case code.ARROW_LEFT:
    case code.ARROW_RIGHT:
      listbox.resetListbox(searchField, listEntries);
      ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);
      break;
    default:
      if (searchTerm.length > 0) {
        ariaLiveRegion.textContent = getAriaLiveTextForResults(listEntries.length, searchTerm);
      }
      break;
  }
};

/**
 * Handles keydown events for the autosuggest listbox.
 *
 * - Exits immediately if the autosuggest listbox is not open.
 * - If the key pressed is not "Enter", calls the listbox
 *     preventDefaultOnKeyDown and exits.
 * - Retrieves the currently selected list item:
 *   - If no item is selected, the function exits.
 *   - If the selected item has no valid text, the function exits.
 *   - Otherwise, it dispatches a termSelected event.
 *
 * @param {HTMLInputElement} searchField - The search input field.
 * @param {HTMLElement} suggestionList - The autosuggest listbox.
 * @param {KeyboardEvent} e - The keydown event object.
 */
const onKeyDown = (searchField, suggestionList, e) => {
  if (!autosuggestListboxIsOpen()) return;
  const keyCodeVal = getKeyCode(e);

  // handler for navigation key events
  // see listbox - this only applies to certain keys
  if (keyCodeVal !== code.ENTER && keyCodeVal !== code.NUMPAD_ENTER) {
    listbox.preventDefaultOnKeyDown(e);
    return;
  }

  // handler for potential selection of search terms
  // we can use aria to determine if this is a listbox selection
  const li = listbox.getSelectedElementByAria(searchField, suggestionList);
  if (!li) return;

  const { id, selectedText } = listbox.getTextAndIdFromListEl(li);
  if (!selectedText) return;

  e.preventDefault();
  listbox.dispatchTermSelectedEvent(searchField, selectedText, id);
};

/**
 * Updates filteredResults, DOM, and/or toggles visibility as needed before invoking
 * onKeyUp method.
 * @param {HTMLDivElement} suggestionBox
 * @param {HTMLInputElement} searchField
 * @param {KeyboardEvent} e
 */
const onInput = (suggestionBox, searchField, e) => {
  const searchTerm = searchField.value.trim();
  /* only update DOM when necessary */
  state.filteredResults = filterResults(searchTerm, state);
  appendResults(state.filteredResults);

  const isVisible = (searchTerm !== ''
    && (state.filteredResults && state.filteredResults.length > 0));
  if (listbox.shouldUpdateVisibility(suggestionBox, isVisible)) {
    setSuggestionBoxVisibility(suggestionBox, searchField, isVisible);
  }

  // needed to update aria live region
  onKeyUp(e);
};

/* init */
const init = (selectors) => {
  const searchElems = getDomElems(selectors);
  if (searchElems === null) return;
  /* build state */
  const {
    searchField,
    suggestionBox,
    suggestionList,
    searchForm,
  } = searchElems;
  const ariaLiveRegion = searchForm.parentElement.querySelector(LISTBOX.ARIA_LIVE.SELECTOR);
  searchField.setAttribute('aria-activedescendant', '');
  Object.assign(state, searchElems, { ariaLiveRegion });
  /* Add Event Listeners */
  searchField.addEventListener('focus', onFocus.bind(null, suggestionBox));
  searchField.addEventListener('blur', onBlur.bind(null, suggestionBox));
  searchField.addEventListener(
    'keydown',
    onKeyDown.bind(null, searchField, suggestionList)
  );
  searchField.addEventListener('keyup', onKeyUp);
  searchField.addEventListener('input', onInput.bind(null, suggestionBox, searchField));
  suggestionList.addEventListener(
    'mousedown',
    onMouseDown.bind(null, searchField, suggestionList)
  );
  fetchData(getAutosuggestTarget());
};

export { state as suggestState };
export default init;
