/**
 * Listbox Utils | Search History & Autosuggest
 * Search History and Autosuggest are very similar in functionality, but must be able to work
 * independently of one another. This module is for methods that are used in both recents and
 * autosuggest listboxes.
 * @see [Definition of listbox]{@link https://www.w3.org/TR/wai-aria-practices/#Listbox}.
 * Used in reference to [combo box]{@link https://www.w3.org/TR/wai-aria-practices/#combobox}
 */
import { code, getKeyCode, keyCode } from '../../../../_shared-components/keyCode';
import LISTBOX from './constants';

/**
 * Keys whose default action should be ignored on a keydown event.
 * @param {KeyboardEvent} e
 */
const preventDefaultOnKeyDown = (e) => {
  const keyCodeVal = getKeyCode(e);
  switch (keyCodeVal) {
    case code.ARROW_DOWN:
    case keyCode.ARROW_DOWN:
    case code.ARROW_UP:
    case keyCode.ARROW_UP:
    case code.END:
    case keyCode.END:
      e.preventDefault();
      break;
    default:
      break;
  }
};

const getUpdatedIndexOnArrowDown = (currentIndex, length) => (
  (currentIndex < length - 1 && currentIndex > -1) ? currentIndex + 1 : 0
);

const getUpdatedIndexOnArrowUp = (currentIndex, length) => (
  (currentIndex <= length && currentIndex > 0) ? currentIndex - 1 : length - 1
);

/**
 * Sets the input field with the value.
 * @param {HTMLInputElement} searchField
 * @param {string} value
 */
const setSearchField = (searchField, value = '') => {
  const search = searchField;
  search.value = value;
};

/**
 * Iterates through list entries and removes css indicating visual focus.
 * @param {HTMLLIElement[]} listEntries
 */
const cleanListEntries = (listEntries) => {
  [...listEntries].forEach((elem) => {
    elem.classList.remove(LISTBOX.CSS.VISUAL_FOCUS);
    elem.removeAttribute('aria-selected');
  });
};

const resetListbox = (searchField, listEntries) => {
  searchField.setAttribute('aria-activedescendant', '');
  cleanListEntries(listEntries);
};

/**
 * Index for the list item element is the last substring of the id when split on '-'.
 * @param {HTMLLIElement} elem
 * @returns {number|number}
 */
const getIndexFromElement = (elem) => {
  const { id = null } = elem || {};
  const wordsArr = !id ? [] : id.split('-');
  return wordsArr.length < 1 ? -1 : (wordsArr[wordsArr.length - 1] * 1);
};

/**
 * Adds visual focus to a single listItem and updates aria-live region with descriptive context.
 * @param {HTMLInputElement} searchField
 * @param {HTMLLIElement} listItem - entry to add visual focus to
 * @param {HTMLLIElement[]} listEntries - button element only for clear history
 */
const setVisualFocus = (searchField, listItem, listEntries) => {
  cleanListEntries(listEntries);
  searchField.setAttribute('aria-activedescendant', listItem.id);
  listItem.setAttribute('aria-selected', 'true');
  listItem.classList.add(LISTBOX.CSS.VISUAL_FOCUS);
};

/**
 * @param {HTMLDivElement} listboxElem - which contains the data-visible attribute which may
 * need to be updated
 * @param {boolean} newValue - most recent result of logic determining if listbox should visible
 * (true) or hidden (false)
 * @returns {boolean} - true if a difference exists between the previous and newValue
 */
const shouldUpdateVisibility = (listboxElem, newValue) => {
  const { dataset: { visible: visibleValue } } = listboxElem;
  const oldValue = visibleValue === 'visible';
  return oldValue !== newValue;
};

const addMouseMoveEventListeners = (dynamicElem, enterCallback, leaveCallback) => {
  dynamicElem.addEventListener('mouseenter', enterCallback);
  dynamicElem.addEventListener('mouseleave', leaveCallback);
};

/**
 * A custom event for when a search term is selected.
 * Used to indicate to search.js to handle the search input submit event
 * and send analytics. This keeps the submission/analytics concern
 * housed ONLY in search.js
 *
 * @constant {CustomEvent} termSelectedEvent
 * @event termSelected
 */
const termSelectedEvent = new CustomEvent(
  'termSelected',
  {
    bubbles: true,
    cancelable: true,
  },
);

/**
 * Retrieves the `id` and `textContent` of a list item (`<li>`).
 * Used for updating the search field when an autosuggest or
 * search history term is selected
 *
 * @param {HTMLElement} li - The <li> element to extract data from.
 * @returns {{ id: string, selectedText: string } | null}
 *   An object containing the `id` and trimmed `selectedText`,
 *   or null if li is not an <li>.
 */
const getTextAndIdFromListEl = (li) => {
  if (li instanceof HTMLLIElement) {
    return {
      id: li.id,
      selectedText: li.textContent.trim(),
    };
  }
  return null;
};

/**
 * Retrieves the currently selected <li> element using the
 *  aria-activedescendant attribute.
 *
 * @param {HTMLInputElement} searchField - The search input field.
 * @param {HTMLElement} listbox - The listbox element containing the options.
 *   (search history or autosuggest)
 * @returns {HTMLLIElement | null} - The selected <li> element, or null
 */
const getSelectedElementByAria = (searchField, listbox) => {
  const id = searchField?.getAttribute('aria-activedescendant');
  const li = id ? listbox?.querySelector(`#${id}`) : null;
  return li && li instanceof HTMLLIElement ? li : null;
};

/**
 * Determines the type of listbox (search history or autosuggest)
 *   based on the aria-activedescendant value.
 *
 * @param {HTMLInputElement} searchField - The search input field.
 * @returns {'search-history' | 'autosuggest' | null} - The type of listbox,
 *   or `null` if unknown.
 */
const getListboxType = (searchField) => {
  const id = searchField?.getAttribute('aria-activedescendant');
  if (id?.startsWith('search-history')) return 'search-history';
  if (id?.startsWith('autosuggest')) return 'autosuggest';
  return null;
};

/**
 * Updates the aria-activedescendant attribute and sets the search field value.
 *
 * @param {HTMLInputElement} searchField - The search input field.
 * @param {string} selectedText - The text to set in the search field.
 * @param {string} [id=''] - The ID of the selected listbox item.
 */
const updateAriaAndSearchTerm = (
  searchField,
  selectedText,
  id = '',
) => {
  setSearchField(searchField, selectedText);
  searchField.setAttribute('aria-activedescendant', id);
};

/**
 * Updates the aria-activedescendant attribute and sets the search field value.
 * and dispatches a `termSelected` event when a search term is chosen
 *
 * @param {HTMLInputElement} searchField - The search input field.
 * @param {string} selectedText - The selected search term.
 * @param {string} [id=''] - The ID of the selected item.
 */
const dispatchTermSelectedEvent = (
  searchField,
  selectedText,
  id = '',
) => {
  if (selectedText) {
    updateAriaAndSearchTerm(searchField, selectedText, id);
    document.dispatchEvent(termSelectedEvent);
  }
};

export {
  addMouseMoveEventListeners,
  getUpdatedIndexOnArrowDown,
  getUpdatedIndexOnArrowUp,
  getIndexFromElement,
  preventDefaultOnKeyDown,
  resetListbox,
  shouldUpdateVisibility,
  setSearchField,
  setVisualFocus,
  termSelectedEvent,
  getTextAndIdFromListEl,
  getSelectedElementByAria,
  getListboxType,
  updateAriaAndSearchTerm,
  dispatchTermSelectedEvent,
};
